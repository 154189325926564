import {
  membersAppDefId,
  myWishlistInstallDefinition,
  ecomAppDefID,
  myAdressesInstallDefinition,
  myWalletInstallDefinition,
  orderHistoryPageId,
  wishlistPageId,
  myOrdersInstallDefinition,
  wishlistAppDefId,
  membersPagesContentKeys,
  membersPagesScreenshots,
  membersPagesFullDescriptionKeys,
  lightboxes,
} from '../constants';
import {Logger} from '@wix/bi-logger-ec-sf';
import _ from 'lodash';
import {FetchError} from '@wix/wixstores-client-core/dist/src/http/FetchError';
import {BulkInstallResponse} from '../types';
import {errorReporter} from '@wix/wixstores-client-core/dist/es/src/viewer-script/errorReporter';

export class DependantApps {
  constructor(
    private readonly sdk: IEditorSdk,
    private readonly appToken: string,
    private readonly biLogger: Logger,
    private readonly instance: string
  ) {
    //
  }

  private getMembersApi() {
    return this.sdk.application.getPublicAPI(this.appToken, {appDefinitionId: membersAppDefId});
  }

  public async isAppInstalled(appDefinitionId: string): Promise<boolean> {
    return this.sdk.tpa.isApplicationInstalled(this.appToken, {
      appDefinitionId,
    });
  }

  public async isMembersInstalled(): Promise<boolean> {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: orderHistoryPageId});
  }

  public addCheckout() {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: 'checkout'}).then((isInstalled) => {
      if (!isInstalled) {
        return this.sdk.tpa.add.component(this.appToken, {
          appDefinitionId: ecomAppDefID,
          componentType: 'PAGE',
          page: {
            pageId: 'checkout',
            shouldNavigate: false,
          },
        });
      }
    });
  }

  public installWishlistTPA(): Promise<void> {
    return fetch('/_api/site-apps/v1/site-apps/bulk-install', {
      method: 'post',
      body: JSON.stringify({
        apps: [{id: wishlistAppDefId}],
      }),
      headers: {
        Authorization: this.instance,
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.biLogger.wishlistInstallationStage({stage: `requestId${response.headers.get('x-wix-request-id')}`});
          console.error(response);
          errorReporter(new FetchError('bulk install failed', {response}));
          throw new FetchError('bulk install failed', {response});
        }
        return response.json();
      })
      .then((data: BulkInstallResponse) => {
        if (!data.installedApps[wishlistAppDefId].instanceId) {
          throw new Error('bulk install failed - no instanceId was returned');
        }
      });
  }

  public async tryInstallWishlist(translations, assetsUrl): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-init'});
    await this.installWishlistTPA().catch(errorReporter);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-after-create-installWishlistTPAPromise'});
    await this.getMembersApi().addApplications([
      this.getMembersAreaPageWithTranslatedContent(myWishlistInstallDefinition, translations, assetsUrl),
    ]);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({
      stage: 'tryInstallWishlist-after-create-installWishlistSectionInMembersPromise',
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-after-promiseAll'});
  }

  public async installWishlistPageInMembersArea(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-init'});
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      this.isMembersInstalled(),
      this.sdk.tpa.isAppSectionInstalled(this.appToken, {
        sectionId: wishlistPageId,
      }),
    ]);
    if (isMembersInstalled && !isMyWishlistInstalled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-inside-if'});
      const installWishlistTpaPromise = this.installWishlistTPA();

      const installWishlistSectionInMembersPromise = this.getMembersApi().addApplications([
        myWishlistInstallDefinition,
      ]);
      await Promise.all([installWishlistSectionInMembersPromise, installWishlistTpaPromise]);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-after-promise-all'});
      await this.sdk.document.save().catch(() => {
        //
      });
    }
  }

  public async uninstallWishlistPageInMembersArea(): Promise<void> {
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      this.isMembersInstalled(),
      this.sdk.tpa.isAppSectionInstalled(this.appToken, {
        sectionId: wishlistPageId,
      }),
    ]);
    if (isMembersInstalled && isMyWishlistInstalled) {
      await this.getMembersApi().removeMembersAreaPage(myWishlistInstallDefinition.pageId);
      await this.sdk.document.save().catch(() => {
        //
      });
    }
  }

  private getMembersAreaPageWithTranslatedContent(appDef, translations, assetsUrl) {
    const content = {page: _.mapValues(membersPagesContentKeys[appDef.pageId], translations)};
    const descriptionBullets = membersPagesFullDescriptionKeys[appDef.pageId].bullets
      .map((key) => `<li>${translations(key)}</li>`)
      .join('');
    content.page.description = `<div><span>${translations(
      membersPagesFullDescriptionKeys[appDef.pageId].title
    )}</span><ul>${descriptionBullets}</ul></div>`;
    content.page.iconURL = `${assetsUrl}/images/wixstores-54px.png`;
    content.page.screenshots = membersPagesScreenshots[appDef.pageId].map(
      (fileName) => `${assetsUrl}/images/${fileName}`
    )[0];
    return {...appDef, ...content};
  }

  private getMembersAreaPageForFutureInstallation(appDef) {
    return {...appDef, shouldInstallInitially: false};
  }

  public getMembersAreaAppsToInstall(translations, assetsUrl): any[] {
    return [
      this.getMembersAreaPageWithTranslatedContent(myOrdersInstallDefinition, translations, assetsUrl),
      this.getMembersAreaPageWithTranslatedContent(myAdressesInstallDefinition, translations, assetsUrl),
      this.getMembersAreaPageWithTranslatedContent(myWalletInstallDefinition, translations, assetsUrl),
      this.getMembersAreaPageWithTranslatedContent(
        this.getMembersAreaPageForFutureInstallation(myWishlistInstallDefinition),
        translations,
        assetsUrl
      ),
    ];
  }

  public getWishlistPageRef(): Promise<any> {
    return this.getMembersApi().getMembersPageRef({appDefinitionId: ecomAppDefID, appPageId: wishlistPageId});
  }

  public async addLighboxes(applicationId: string) {
    const popupPages = await this.sdk.pages.popupPages.getApplicationPopups(ecomAppDefID);

    for (const lightbox of lightboxes) {
      const pageExists = !!popupPages.find((page) => page.tpaPageId === lightbox.tpaPageId);
      if (pageExists) {
        continue;
      }

      const componentDefinition = {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPASectionSkin',
        layout: {
          width: 980,
          height: 693,
          x: 0,
          y: 40,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        data: {
          type: 'TPAMultiSection',
          applicationId: applicationId.toString(),
          appDefinitionId: ecomAppDefID,
          widgetId: lightbox.widgetId,
        },
      };

      await this.sdk.document.pages.popupPages.add(lightbox.tpaPageId, {
        title: lightbox.title,
        definition: {
          data: {
            managingAppDefId: ecomAppDefID,
            tpaPageId: lightbox.tpaPageId,
            pageBackgrounds: {
              desktop: {
                custom: true,
                ref: {
                  type: 'BackgroundMedia',
                  color: '#A5C8DE',
                  colorOpacity: 0.75,
                  alignType: 'top',
                  fittingType: 'fill',
                  scrollType: 'fixed',
                },
                isPreset: true,
              },
            },
          },
          components: [
            {
              type: 'Container',
              components: [componentDefinition],
              skin: 'wysiwyg.viewer.skins.area.RectangleArea',
              layout: {
                width: 980,
                height: 693,
                x: 0,
                y: 40,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
                docked: {
                  hCenter: {px: 0},
                  vCenter: {px: 0},
                },
              },
              componentType: 'wysiwyg.viewer.components.PopupContainer',
              props: {
                type: 'PopupContainerProperties',
              },
            },
          ],
        },
        shouldNavigateToPage: false,
      });
    }
  }
}
